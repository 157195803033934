//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import EntityThumbnail from '@/components/EntityThumbnail.vue'
export default {
    name: 'EventMicroView',
    components:{
        EntityThumbnail
    },
    props: ['event', 'doNotShowMenu'],
    data(){
        return {
            image:''
        }
    },
    methods:{
         fetchThumbnails(id){
            this.payload = {
                id: id
            }
            this.$store.dispatch('event/getCoverPhoto',this.payload)
            .then((r) => {
               r.status == 'success' ?  this.image = r.image: ''
            })
        },
        deleteEvent(id){
           this.$store.dispatch('event/deleteEvent',id)
           .then((response) =>{
                if(response == 'success'){
                    this.$q.notify({
                        message:"The event has been deleted.",
                        color: 'primary',
                        icon: 'event_busy'
                    })
                    location.reload()
                }else{
                    this.$q.notify({
                        message:'We cannot process your request right now.',
                        color: 'negative',
                        icon: 'error'
                    })
                }
            })
        },
        EditStore(id){
            this.$router.push({name:'StoreSettings',params:{store_id:id}})
        }
    },
    filters: {
        moment: function (date,date_end) {
            return date_end == date ? moment(new Date(date)).format('MMMM DD, YYYY') :   moment(new Date(date)).format('MMMM DD, YYYY') + ' - ' + moment(new Date(date_end)).format('MMMM DD, YYYY')
        }
    },
    created(){
        this.fetchThumbnails(this.event.id)
    }
}
